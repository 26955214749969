/* global config, Promise */

'use strict';

angular.module('managerApp').controller('TerminalsController', ($scope, $rootScope,
        $location, $route, $cookies, $routeParams, $window, $mdDialog, toast, terminals, procedureTools) => {

//------------------------------------------------------------------------------

    $scope.config = config;
    const path = $location.path();
    const currentDir = 'app/terminals/';

//------------------------------------------------------------------------------

    $scope.pagination = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: $cookies.get('pagination.terminals.itemsPerPage') || 10,
        totalItems: 0,
        pageChanged: () => {
            $cookies.put('pagination.terminals.itemsPerPage', $scope.pagination.itemsPerPage);
            $scope.getTerminals();
        }
    };

    $scope.count = 0;
    $scope.items = [];

    $scope.terminalId = $routeParams.id;
    $scope.isEdit = typeof $scope.terminalId !== 'undefined';

    $scope.terminal = null;

    $scope.devicePartners = [];
    $scope.activeTerminalPartners = [];

//------------------------------------------------------------------------------

    $scope.openMenu = function ($mdMenu, e) {
        $mdMenu.open(e);
    };

    $scope.showSearchPanel = () => {
        $rootScope.$broadcast('show-search-panel');
    };

    $rootScope.$on('get-terminals', (e) => {
        $scope.pagination.filters = e.targetScope.search.filters;
        $scope.getTerminals();
    });

//---------------------------------------------------------- terminal action ---

    $scope.ping = (item, index) => {
        terminals.get({
            id: item._id,
            ping: true
        }).then((data) => {
            if (data) {
                item.online = data.online;
                item.lastPing = data.lastPing;
            }
            $scope.$apply();
        }).catch((err) => {
            toast.show(err, 'error');
        });
    };

    $scope.toggleActive = (item) => {
        if (confirm('Voulez-vous vraiment ' + (item.enabled ? 'dés' : '') + 'activer cette borne ?')) {
            item.enabled = !item.enabled;
            terminals.update(item, item._id).then(() => {
                toast.show('La borne "' + item.name + '" a bien été mise à jour.', 'success');
            }).catch(() => {
                toast.show('La borne "' + item.name + '" n\'a pas pu être mise à jour.', 'error');
                item.enabled = !item.enabled;
            });
        }
    };

    $scope.showDeleteConfirm = ($event, terminal) => {
        $mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: $event,
            template:
                    `<md-dialog layout-padding flex="35" aria-label="List dialog">
                    <md-dialog-content layout="row" layout-wrap>
                        <div flex='100'>
                            <h3 style='margin: 0; margin-bottom: 10px;'>
                                <md-icon style='font-size: 24px; color: red'>warning</md-icon> Suppression
                            </h3>
                            <md-divider></md-divider><br>
                        </div>
                        <div>
                            <p>Voulez-vous vraiment supprimer la borne "<b>` + terminal.name + `</b>" ?</p>
                            <p>Cette opération sera exécutée immédiatement et elle est <b>irréversible</b> !</p>
                        </div>
                    </md-dialog-content>
                    <md-dialog-actions>
                        <div class='buttons-container'>
                            <md-button ng-click="closeDialog()"
                                class="md-button green btn">Annuler</md-button>
                            <md-button ng-click="closeDialog(true)" 
                                class="btn btn-submit red">Confirmer la suppression</md-button>
                        </div>
                    </md-dialog-actions>
                  </md-dialog>`,
            locals: {
                terminalId: terminal._id
            },
            controller: ($scope, $mdDialog, toast, terminals, terminalId) => {
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm) {
                        terminals.delete(terminalId).then(() => {
                            toast.show('La borne a bien été supprimée.', 'success');
                            $scope.getTerminals();
                        }).catch((err) => {
                            toast.show('Une erreur est survenu lors de la suppression' +
                                    ' de cette borne.');
                        });
                    }//
                };
            }
        });
    };

    $scope.updateActivationCode = (item) => {
        let msg = 'Le code d\'activation de la borne ';
        terminals.updateActivationCode(item._id).then((terminal) => {
            item.activationCode = terminal.activationCode;
            toast.show(msg + 'a bien été mis à jour.', 'success');
        }).catch(() => {
            toast.show(msg + 'n\'a pas pu être mis à jour.', 'error');
        });
    };

    $scope.startRemoteSession = (item) => {
        if (!item.remoteId) {
            return;
        }
        $window.open('anydesk://' + item.remoteId, '_blank');
    };

    // by default, instant processing is enabled
    $scope.disableInstantProcessing = (item) => {
        setInstantProcessing(item === 'all' ? item : item._id, false);
    };

    $scope.enableInstantProcessing = (item) => {
        setInstantProcessing(item === 'all' ? item : item._id, true);
    };
    const getPartnersTerminals = () => {
        let params = {
            partners: 1
        };
        const path_parts = $scope.currentPath.split("/")
            $scope.pagination.filters= path_parts.length>3 ? {PartnerId:path_parts[4]} :  {};
        if ($scope.pagination.filters) {
            for (let i in $scope.pagination.filters) {
                params[i] = $scope.pagination.filters[i];
            }
        }
        terminals.get(params, $scope.pagination.currentPage,
                $scope.pagination.itemsPerPage).then((data) => {
            $scope.count = data.count;
            $scope.items = data.items;
            $scope.pagination.totalItems = data.count;
            $scope.$apply();
        }).catch((err) => {
            $scope.error = err;
        });
    };
    function setInstantProcessing(target, enable) {
        let v = (enable ? '' : 'dé') + 'activer',
                t = target === 'all' ? 'toutes les bornes' : 'cette borne',
                data = {instantProcessing: enable};
        if (confirm('Voulez-vous vraiment ' + v + ' le traitement instantané pour ' + t + ' ?')) {
            terminals.update(data, target).then(() => {
                showUpdateToast(target === 'all', true);
                updateTarget(target, 'instantProcessing', enable);
            }).catch(() => {
                showUpdateToast(target === 'all', false);
            });
        }
    }

    // by default, standby mode is disabled
    $scope.enableStandby = (item) => {
        setStandby(item === 'all' ? item : item._id, true);
    };

    $scope.disableStandby = (item) => {
        setStandby(item === 'all' ? item : item._id, false);
    };

    function setStandby(target, enable) {
        let v = (enable ? '' : 'dé') + 'activer',
                t = target === 'all' ? 'toutes les bornes' : 'cette borne',
                data = {standby: enable};
        if (confirm('Voulez-vous vraiment ' + v + ' le mode "veille" pour ' + t + ' ?')) {
            terminals.update(data, target).then(() => {
                showUpdateToast(target === 'all', true);
                updateTarget(target, 'standby', enable);
            }).catch(() => {
                showUpdateToast(target === 'all', false);
            });
        }
    }

    function updateTarget(target, attr, value) {
        for (let i = 0; i < $scope.items.length; i++) {
            let item = $scope.items[i];
            if (target === 'all') {
                item[attr] = value;
            } else {
                if (item._id === target) {
                    item[attr] = value;
                    break;
                }
            }
        }
    }

    function showUpdateToast(allItems, success) {
        if (success) {
            toast.show(allItems
                    ? 'Les bornes ont bien été mises à jour'
                    : 'La borne a bien été mise à jour', 'success');
        } else {
            toast.show(allItems
                    ? 'Les bornes n\'ont pas pu être mises à jour'
                    : 'La borne n\'a pas pu être mise à jour', 'error');
        }
    }

//------------------------------------------------------------------------------
 // get all terminals list
    function getTerminals() {
        let params = {};
        if ($scope.pagination.filters) {
            for (let i in $scope.pagination.filters) {
                let value = $scope.pagination.filters[i];
                if (value !== '') {
                    if (i === 'terminal') {
                        if (value === null) {
                            delete params.ClientId;
                        } else {
                            params.ClientId = value._id;
                        }
                    } else {
                        params[i] = $scope.pagination.filters[i];
                    }
                }
            }
        }
        terminals.get(params, $scope.pagination.currentPage, $scope.pagination.itemsPerPage).then((data) => {
            $scope.count = data.count;
            $scope.items = data.items || [];
            $scope.pagination.totalItems = data.count;
            for (let i = 0; i < $scope.items.length; i++) {
                let item = $scope.items[i];
                item.TerminalPartner.Client.personType = 'personne-physique';
                item.terminalName = procedureTools.getPersonName(item.TerminalPartner.Client);
            }
            $scope.$apply();
        }).catch((err) => {
            toast.show(err, 'error');
        });
    }

    // get active terminal parteners only list (without cache)
    function getActiveTerminalPartners(name = null) {
        let params = {
            active: 1,
            partners: 1,
            namesIdsOnly: 1,
            prepaidAccount: 1
        };
        if (typeof name === 'string') {
            params.name = name;
        }
        return terminals.get(params, null, 100).then((partners) => {
            $scope.activeTerminalPartners = partners.items;
            return Promise.resolve(partners.items);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }

    // get a terminal with its id
    function getTerminal(id) {
        if (!id)
            return;
        terminals.get({id: id}).then((terminal) => {
            $scope.terminal = terminal;
            getProcedures($scope.terminalId);

        }).catch((err) => {
            toast.show('Compte terminal introuvable', 'error').then(() => {
                $location.path('/terminals');
            });
        });
    }

    function getProcedures(id) {
        let params = {
            id: $scope.terminalId
        };
//        if ($scope.paginationproced.filters) {
//            let filters = $scope.paginationproced.filters;
//            for (let i in filters) {
//                params[i] = filters[i];
//            }
//        }
        terminals.getproced(params, $scope.paginationproced.currentPage,
                $scope.paginationproced.itemsPerPage).then((data) => {
            $scope.proceduresCount = data.count;
            $scope.procedures = data.items;
            for (let i = 0; i < $scope.procedures.length; i++) {
                let procedure = $scope.procedures[i];
                procedure.origin = procedureTools.getOrigin(procedure);
                procedure.isHandled = procedureTools.isHandled(procedure);
                procedure.isHandledByUser = procedure.UserId === $rootScope.currentUser._id;
            }
            $scope.paginationproced.totalItems = data.count;
            $scope.$apply();
        });
    }

    $scope.showAddEditTerminalPopup = (terminal = null) => {
        $mdDialog.show({
            templateUrl: currentDir + 'add.edit.terminal.html',
            parent: angular.element(document.body),
            fullscreen: true,
            locals: {
                terminal: terminal,
                getPartners: getActiveTerminalPartners
            },
            controller: ($scope, toast, terminal, getPartners) => {
                $scope.isEdit = terminal !== null;
                $scope.terminal = terminal === null ? {} : terminal;
                $scope.selectedPartner = null;
                $scope.prepaidAccounts = [];
                if (terminal) {
                    let pa = terminal.PrepaidAccountId;
                    if (terminal.TerminalPartner.Client.PrepaidAccount !== null && pa === null) {
                        pa = terminal.TerminalPartner.Client.PrepaidAccount._id;
                    }
                    if (pa !== null) {
                        $scope.prepaidAccounts = [
                            {
                                id: null,
                                name: 'Aucun'
                            },
                            {
                                id: pa,
                                name: 'Compte prépayé N° ' + pa
                            }
                        ];
                    }
                }
                getPartners(null).then((data) => {
                    $scope.terminalPartners = data;
                    if (terminal) {
                        $scope.selectedPartner = $scope.terminalPartners.find(partner => partner._id === terminal.TerminalPartner._id);
                    }
                });
                $scope.onPartnerSelect = function () {
                    $scope.terminal.TerminalPartnerId = $scope.selectedPartner._id;
                    $scope.prepaidAccounts = [];
                    if ($scope.selectedPartner.Client.PrepaidAccount !== null) {
                        let pa = $scope.selectedPartner.Client.PrepaidAccount;
                        $scope.prepaidAccounts = [
                            {
                                id: null,
                                name: 'Aucun'
                            },
                            {
                                id: pa._id,
                                name: 'Compte prépayé N° ' + pa._id
                            }
                        ];
                    }
                };
                $scope.closeDialog = (confirm = false) => {
                    if (confirm) {
                        let promise = $scope.isEdit ? terminals.update($scope.terminal, $scope.terminalId) : terminals.add($scope.terminal);
                        promise.then(() => {
                            $mdDialog.hide();
                            toast.show('La borne a bien été ' + ($scope.isEdit ? 'mise à jour' : 'ajoutée.'), 'success', true, 2500);
                            $route.reload();
                        }).catch((err) => {
                            toast.show(err, 'error');
                        });
                    } else {
                        $mdDialog.hide();
                    }//
                };
            }
        }).then((answer) => {
            //
        }, () => {
            //
        });
    };

    $scope.sendActionEvent = (terminal, action = null) => {
        if (typeof action !== 'string') {
            return;
        }
        if (confirm('Voulez-vous vraiment envoyer cette action sur cette borne ?')) {
            terminals.sendActionEvent(terminal._id, action).then(() => {
                toast.show("L'action a bien été envoyée à la borne", 'success', true, 2500);
            }).catch((err) => {
                toast.show(err, 'error');
            });
        }//
    };

//------------------------------------------------------------------------------

    switch (path.substr(0, 14)) {

        case '/terminals':
            $scope.getTerminals();
            break;
        case '/terminals/par':
            getPartnersTerminals();
            break;
        case '/terminals/add':
            getActiveTerminalPartners();
            $scope.terminal = {
                name: '',
                site: '',
                phone: '',
                email: '',
                location: '',
                adresse: ''
            };
            break;
        default:
            getTerminal($scope.terminalId);
    }

    $scope.sendData = () => {
        let terminal = $scope.terminal;
        let promise = $scope.isEdit ? terminals.update(terminal, $scope.terminalId) : terminals.add(terminal);
        promise.then(() => {
            toast.show('La borne a bien été ' +
                    ($scope.isEdit ? 'mise à jour' : 'ajoutée.'), 'success', true).then(() => {
                if (!$scope.isEdit) {
                    $location.path('/terminals');
                }
            });
        }).catch((err) => {
            toast.show(err, 'error');
        });
    };

});
